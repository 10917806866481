import { Cloudinary } from "components/cloudinary";
import { Container } from "components/container";
import { Icon, Shape } from "components/shape";

import HeadingWrapper from "../heading";
import { getEdgeSettings } from "components/wrapper/components";
import { colors } from "theme/theme";
import { WrapperComponentProps } from "constants/types";
import { FC } from "react";
import { Im } from "types";
import Link from "next/link";

export interface ImageMapAnchor {
  coordinates: string;
  icon: Icon;
  icon_on_the_right: boolean;
  link: string;
  title: string;
  variant: "blue" | "blue_outline" | "orange" | "orange_outline";
}

const parseCoordinates = (
  rawCoordinates: string,
): { x?: number; y?: number } => {
  try {
    const coordinates = JSON.parse(
      rawCoordinates.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": '),
    );
    if (!coordinates.x) coordinates.x = undefined;
    if (!coordinates.y) coordinates.y = undefined;
    return coordinates;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return { x: undefined, y: undefined };
  }
};

const Anchor = ({
  title,
  coordinates,
  link,
  icon,
  variant,
  icon_on_the_right,
}: ImageMapAnchor) => {
  const parsedCoordinates = parseCoordinates(coordinates);
  const color = variant?.split("_")?.[0] || "blue";
  const isOutline = variant?.includes("outline");

  const A = link.startsWith("/") ? Link : "a";

  return (
    <A
      href={link}
      rel="noreferrer"
      className="component__imagemap__wrapper__labels__label"
      style={{
        left: `${parsedCoordinates.x}%`,
        top: `${parsedCoordinates.y}%`,
      }}
    >
      <span className={icon_on_the_right ? "left" : "right"}>{title}</span>
      <Shape
        className={`${isOutline ? "outline " + color : "background " + color}`}
        variant={icon}
        fill={isOutline ? colors?.[color] || colors.blue : "white"}
        size={18}
      />
    </A>
  );
};

interface ImageMapWrapperProps extends WrapperComponentProps {
  links?: ImageMapAnchor[];
  select_images?: Im[];
}

const ImageMapWrapper: FC<ImageMapWrapperProps> = (props) => {
  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={`component__imagemap-${props.index}`}
      className="section component__imagemap"
    >
      <HeadingWrapper {...props} />

      <div className="component__imagemap__wrapper">
        <Cloudinary media={props.select_images[0]} />
        <div className="component__imagemap__wrapper__labels">
          {props.links.map((props, index) => (
            <Anchor {...props} key={props?.coordinates + index} />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default ImageMapWrapper;
